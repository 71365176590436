@import '../../../../../components/variables.css';

.edit-product-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-product-content{
    width: 50%;
    padding: 2.5rem;
    background-color: white;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    font-family: var(--font-family-main);
}

.edit-product-header{
    font-size: var(--font-size-text);
    margin-bottom: 3%;
}

.edit-product-form{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    overflow-y: auto;
    max-height: 80vh;
}

.edit-product-current-image{
    color: var(--color-chocolate);
    font-size: var(--font-size-text);
}

.edit-product-current-image img{
    width: 150px;
    height: 150px;
}

.form-actions{
    display: flex;
    gap: 1rem;
}

.approve-action-btn, .cancel-action-btn{
    margin-bottom: 2.5rem;
    cursor: pointer;
    border: none;
    padding: 10px;
    border-radius: 25px;
    background-color: #93fc5f;
    width: 150px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    transition: .5s ease;
    text-decoration: none;
    font-family: var(--font-family-main);
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
}

.approve-action-btn{
    background-color: #93fc5f;
}

.cancel-action-btn{
    background-color: #fc5f5f;
}

#add-product-file-input {
    position: absolute;
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    z-index: -1;
}

.add-product-file-label {
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 10px;
    background: linear-gradient(90deg, rgba(218,156,95,1) 4%, rgba(236,212,159,1) 98%);
    color: #fff;
    transition: .5s ease-in-out;
    display: inline-block;
}

.add-product-file-label:hover {
    transform: scale(1.02);
}