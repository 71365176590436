@import '../variables.css';

.footer-wave{
    width: 100%;
    height: 150px;
    transform: rotate(180deg) translateY(-10px);
}

.footer-container {
    bottom: 0;
    padding: 3% 5%;
    background: rgb(218,156,95);
    background: linear-gradient(90deg, rgba(218,156,95,1) 4%, rgba(236,212,159,1) 94%);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 2rem;
    font-family: var(--font-family-main);
    color: var(--color-chocolate);
    text-align: center;
}

.footer-category{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


.footer-content-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    flex: 1;
}

.footer-contact-container{
    justify-content: flex-start;
    gap: 1rem;
}

.footer-content-container, .footer-content-container a{
    list-style: none;
    text-decoration: none;
    font-size: var(--font-size-text);
    color: var(--color-chocolate);
    transition: .5s ease;
}

.footer-content-container a{
    cursor: pointer;
}

.footer-content-container a:hover{
    color: white;
}

.footer-socials-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: .5rem;
    align-items: center;
}

.footer-brand-logo{
    width: 150px;
    height: 150px;
}

.social-svg{
    width: 30px;
    height: 30px;
    padding: 5px;
    border: 1px solid black;
    border-radius: 50%;
    cursor: pointer;
    transition: .5s ease;
}

.social-svg:hover{
    background-color: white;
}

@media screen and (max-width: 1023px){
    .footer-container {
        grid-template-columns: 1fr 1fr;
    }

    .footer-socials-category{
        text-align: left;
        grid-column: 1 / span 2;
    }
    
    .footer-socials-container{
        justify-content: flex-start;
    }
}


@media screen and (max-width: 767px){
    .footer-brand-logo{
        width: 130px;
        height: 130px;
    }

    .footer-content-container, .footer-content a{
        font-size: var(--font-size-small-text);
    }
}