@import '../../components/variables.css';

.product-information-section {
    padding: 10%;
    font-family: var(--font-family-main);
}

.product-information-header {
    font-size: var(--font-size-header);
    color: var(--color-chocolate);
    margin-bottom: 1.5rem;
}

.product-information {
    margin-top: 8%;
    display: flex;
    gap: 5rem;
    height: 450px;
}

.product-information img {
    height: 100%;
}

.product-information-content {
    padding: 3%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.product-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.product-content p {
    margin-bottom: 5%;
}

.product-information-price {
    font-size: var(--font-size-header);
    margin-bottom: 8%;
}

.product-information-cta-btn {
    color: black;
    width: 300px;
    text-decoration: none;
    font-family: var(--font-family-main);
    cursor: pointer;
    border: none;
    padding: 15px;
    border-radius: 25px;
    background-color: #FCC05F;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    transition: 0.5s ease;
}

.product-information-cta-btn:hover {
    transform: scale(1.05);
}

@media (max-width: 1024px) {
    .product-information {
        gap: 0rem;
    }
    
    .product-information img{
        width: 400px;
        height: auto;
    }
}

@media (max-width: 768px) {
    .product-information {
        flex-direction: column;
        align-items: center;
        height: auto;
    }

    .product-information img {
        width: 100%;
        height: auto;
    }

    .product-information-content {
        padding: 3% 0;
    }
}

@media (max-width: 425px) {
    .product-information-section {
        padding: 10% 5%;
    }

    .product-information-header {
        font-size: var(--font-size-sub-header);
    }

    .product-information-cta-btn {
        width: 100%;
    }
}
