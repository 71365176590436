@import '../../components/variables.css';

.agb-section{
    padding: 15% 10% 5% 10%;
    font-family: var(--font-family-main);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.agb-header{
    font-size: var(--font-size-header);
    color: var(--color-chocolate);
    margin-bottom: 1rem;
}

.agb-content-container{
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.agb-text-container{
    width: 100%;
    font-size: var(--font-size-text);
}

.agb-content-text{
    list-style: none;
    margin-bottom: 1rem;
}


@media only screen and (max-width: 991px){

    .agb-content-container{
        flex-direction: column;
    }

    .agb-text-container{
        width: 100%;
    }
}

@media only screen and (max-width: 575px) {

    .agb-header{
        font-size: var(--font-size-sub-header);
    }

    .agb-content-text{
        font-size: var(--font-size-small-text);
    }
}