@import '../../../../../components/variables.css';

.edit-category-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-category-content{
    width: 50%;
    padding: 2.5rem;
    background-color: white;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    font-family: var(--font-family-main);
}

.edit-category-header{
    font-size: var(--font-size-text);
    margin-bottom: 3%;
}

.edit-category-form{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.edit-category-current-image{
    color: var(--color-chocolate);
    font-size: var(--font-size-text);
}

.edit-category-current-image img{
    width: 150px;
    height: 150px;
}

.form-actions{
    display: flex;
    gap: 1rem;
}

.approve-action-btn, .cancel-action-btn{
    margin-bottom: 2.5rem;
    cursor: pointer;
    border: none;
    padding: 10px;
    border-radius: 25px;
    background-color: #93fc5f;
    width: 150px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    transition: .5s ease;
    text-decoration: none;
    font-family: var(--font-family-main);
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
}

.approve-action-btn{
    background-color: #93fc5f;
}

.cancel-action-btn{
    background-color: #fc5f5f;
}

#add-category-file-input{
    padding: 8px;
    width: max-content;
    border: 1px solid black;
    border-radius: 10px;
}

input[type=file]::file-selector-button {
    background: rgb(218,156,95);
    background: linear-gradient(90deg, rgba(218,156,95,1) 4%, rgba(236,212,159,1) 98%);
    margin-right: 20px;
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: .5s ease-in-out;
  }
  
input[type=file]::file-selector-button:hover {
    transform: scale(102%);
}