@import '../../components/variables.css';

.contact-section{
    padding: 15% 10% 5% 10%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    font-family: var(--font-family-main);
}

.contact-header{
    font-size: var(--font-size-header);
    color: var(--color-chocolate);
}

.contact-form{
    padding: 1rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-input-group{
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}

.form-input-group *{
    width: 50%;
}

.form-textarea-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-family: var(--font-family-main);
    font-size: var(--font-size-text);
}

.form-textarea-container label{
    color: var(--color-chocolate);
}

.form-textarea-container textarea{
    padding: 8px;
    background-color: #F5F7F9;
    height: 250px;
    outline: none;
    border: 1px dashed var(--color-chocolate);
    transition: .5s ease;
    resize: none;
}

.form-textarea-container textarea:focus{
    transform: scale(101%);
}

.contact-send-btn{
    cursor: pointer;
    border: none;
    padding: 15px;
    border-radius: 25px;
    background-color: #FCC05F;
    width: 200px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    transition: .5s ease;
    text-decoration: none;
    font-family: var(--font-family-main);
    color: black;
}

.contact-send-btn:hover{
    transform: scale(102%);
}

@media only screen and (max-width: 575px){

    .contact-header{
        font-size: var(--font-size-sub-header);
    }

    .contact-form{
        font-size: var(--font-size-small-text);
    }

    .form-input-group{
        flex-direction: column;
        gap: 1rem;
    }

    .form-input-group .form-input-container{
        width: 100%;
    }
}