@import '../variables.css';

.mapContainer{
    padding: 0% 10%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    font-family: var(--font-family-main);
    color: var(--color-chocolate);
}

.mapContainer h3{
    font-size: var(--font-size-header);
}

.mapContainerHeader{
    display: flex;
    align-items: flex-start;
}

.mapArrowSVG{
    aspect-ratio: 1/1;
    height: 200px;
}

iframe{
    border-radius: 10px;
    width: 100%;
    height: 500px;
    border: none;
}

@media screen and (max-width: 1023px){

}

@media screen and (max-width: 767px){
    .mapContainer {
      padding: 3% 5%;
    }

    .mapContainerHeader h3{
        font-size: var(--font-size-sub-header);
    }

    .mapArrowSVG{
        height: 130px;
    }
}