@import '../variables.css';

#productSliderContainer {
  padding: 0% 10%;
  display: flex;
  flex-direction: column;
  padding-bottom: 10%;
  gap: 2rem;
  transition: transform 0.3s ease-in-out;
}

.productSliderHeader{
  display: flex;
  align-items: flex-start;
  font-family: var(--font-family-main);
  color: var(--color-chocolate);
}

.productSliderHeader h3{
  font-size: var(--font-size-header);
}

.sliderArrowSVG{
  aspect-ratio: 1/1;
  height: 200px;
}


.goToMenu-cta{
  display: flex;
  align-items: center;
  gap: .5rem;
  margin-left: auto;
  cursor: pointer;
  transition: .3s ease;
  text-decoration: none;
  font-family: var(--font-family-main);
  font-size: var(--font-size-sub-header);
  color: var(--color-chocolate);
}

.goToMenu-cta:hover{
  transform: scale(102%);
}

.productSliderContent{
  display: flex;
  align-items: flex-end;
  gap: 2rem;
  transition: transform 0.3s ease-in-out;
}

.productSliderChildContainer {
  text-align: center;
  height: 400px;
  width: 100%;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.centerChildContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transform: translateY(-5%);
  height: 100%;
}

.productSliderPrimaryImage {
  width: 350px;
  height: 350px;
  object-fit: contain;
  user-select: none;
}

.productSliderSecondaryImage {
  width: 300px;
  height: 300px;
  object-fit: contain;
  user-select: none;
}

.productSliderTextContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-family: var(--font-family-main);
}

.productSliderTitle {
  font-size: var(--font-size-header);
}

.productSliderPrimaryTitle {
  color: var(--color-chocolate);
}

.productSliderParagraph {
  color: #e2e2e2;
  font-size: var(--font-size-text);
  height: fit-content;
}

.productSliderArrows{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  transition: .5s ease;
}

.productSliderArrows:hover{
  transform: scale(105%);
}

.productSliderArrow {
  cursor: pointer;
  width: 35px;
  height: 35px;
  background-color: white;
  color: var(--color-chocolate);
}

@keyframes slideRight {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

@media screen and (max-width: 1024px) {
  .productSliderContent {
    padding: 5% 5%;
    flex-direction: column;
    align-items: center;
  }

  .productSliderChildContainer{
    height: 300px;
  }

  .centerChildContainer{
    justify-content: center;
  }

  .centerChildContent{
    flex: 1;
  }

  .productSliderPrimaryImage {
    width: 200px;
    height: 200px;
  }

  .productSliderSecondaryImage {
    width: 150px;
    height: 150px;
  }
}

@media screen and (max-width: 768px)  {
  #productSliderContainer {
    padding: 3% 5%;
    gap: .5rem;
  }

  .sliderArrowSVG{
    display: none;
  }

  .productSliderChildContainer{
    width: 350px;
    height: 200px;
  }

  .productSliderHeader h3{
    font-size: var(--font-size-sub-header);
  }

  .productSliderTitle{
    font-size: var(--font-size-text);
  }

  .productSliderParagraph{
    font-size: var(--font-size-small-text);
  }

  .productSliderPrimaryImage {
    width: 150px;
    height: 150px;
  }

  .productSliderSecondaryImage {
    width: 100px;
    height: 100px;
  }

  .sliderArrowSVG{
    height: 130px;
  }
}

@media screen and (max-width: 430px){

  .productSliderHeader{
    align-items: flex-start;
    flex-direction: column;
    font-size: var(--font-size-text);
  }

  .productSliderChildContainer{
    width: 300px;
  }

  .goToMenu-cta{
    margin-left: 0;
  }
} 