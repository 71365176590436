@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@700&display=swap');

:root{
    --color-chocolate: #542412;
    --font-family-main: 'Fredoka', sans-serif;
    --font-size-title: 42px;
    --font-size-header: 38px;
    --font-size-sub-header: 28px;
    --font-size-text: 18px;
    --font-size-small-text: 14px;
}