@import '../../components/variables.css';

.navbarChocolate{
    z-index: 1000;
}

.about-hero-section {
    position: relative;
    height: 100vh;
    overflow: hidden;
}

.about-hero-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/public/uploads/franchise/Franchise5.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

.about-hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.about-hero-text {
    font-family: var(--font-family-main);
    font-size: var(--font-size-header);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
}

.about-hero-text-header{
    margin-bottom: 1.5rem;
}

.about-hero-text svg{
    cursor: pointer;
}

.about-content-section{
    padding: 3% 10%;
    display: flex;
    align-items: center;
    gap: 2.5rem;
}

.about-content-text{
    font-family: var(--font-family-main);
    font-size: var(--font-size-text);
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-self: flex-start;
}

.about-content-header{
    font-size: var(--font-size-header);
    color: var(--color-chocolate);
}

.about-content-image{
    width: 50%;
}

.about-content-image img{
    border-radius : 129px 110px 0px 150px;
    width: 100%;
}

.about-menu-cta{
    cursor: pointer;
    border: none;
    padding: 15px;
    border-radius: 25px;
    background-color: #FCC05F;
    width: 250px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    transition: .5s ease;
    text-decoration: none;
    font-family: var(--font-family-main);
    color: black;
}

.about-menu-cta:hover{
    transform: scale(102%);
}

@media screen and (max-width: 1023px) {
    .about-hero-section {
      height: 60vh;
    }
  
    .about-hero-text {
        font-size: var(--font-size-sub-header);
    }
  
    .about-content-section {
      padding: 3% 5%;
      flex-direction: column;
    }
  
    .about-content-text {
      width: 100%;
      font-size: var(--font-size-small-text);
    }

    .about-content-header{
        font-size: var(--font-size-sub-header);
    }
  
    .about-content-image {
      width: 60%;
      margin-bottom: 1.5rem;
    }
  
    .about-menu-cta {
      width: 250px;
      font-size: var(--font-size-small-text);
      align-self: center;
    }
}

@media screen and (max-width: 768px) {

    .waveImage{
        height: 80px;
    }
  
    .about-hero-text {
      font-size: var(--font-size-text);
    }
  
    .about-content-section {
        text-align: center;
        padding: 5% 5% 0% 5%;
    }

    .about-content-header{
        font-size: var(--font-size-text);
    }
  
    .about-content-image {
      width: 80%;
    }

    .about-hideMobile-section{
        flex-direction: column-reverse;
    }
}