@import '../../variables.css';

/* SlideIn Animation for Hero Elements */

@keyframes slideInFromTop {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInFromBottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.heroItemContainer {
  width: 100%;
  display: flex;
  align-items: center;
  height: 80vh;
}

.heroItemContainerLeft {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.heroItemContainerRight {
  width: 50%;
}

.heroItemImage {
  width: 500px;
  height: 600px;
}

.heroText {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #fff;
  font-family: var(--font-family-main);
}

.heroText p {
  font-size: var(--font-size-sub-header);
}

.heroText h4 {
  font-size: var(--font-size-header);
}

.heroActions {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.heroSliderActions {
  display: flex;
  gap: 2rem;
}

.heroSliderActions svg {
  cursor: pointer;
  color: white;
  height: 50px;
  width: 50px;
}

.primaryButton {
  font-family: var(--font-family-main);
  cursor: pointer;
  border: none;
  padding: 15px;
  border-radius: 25px;
  background-color: #FCC05F;
  width: 200px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.sliderButton {
  cursor: pointer;
  padding: 15px;
}

/* Animation Classes */

.slideInTop {
  animation: slideInFromTop .75s ease-out;
}

.slideInLeft {
  animation: slideInFromLeft .75s ease-out;
}

.slideInBottom {
  animation: slideInFromBottom .75s ease-out;
}

/* Mobile Responsiveness */

@media screen and (max-width: 1023px) {
  .heroItemContainer {
    height: auto;
    flex-direction: column-reverse;
  }

  .heroItemImage {
    width: 300px;
    height: auto;
  }
}

@media screen and (max-width: 767px){
  .heroItemContainerLeft,
  .heroItemContainerRight {
    width: 100%;
  }

  .heroItemImage{
    width: 150px;
  }
}
