@import '../../../components/variables.css';

.dashboard-table-container{
    width: 100%;
    padding: 3%;
    position: relative;
}

.dashboard-table{
    padding: 5%;
    width: 100%;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    text-align: left;
    border-collapse: collapse;
    font-family: var(--font-family-main);
}

.table-header-row th{
    padding: 1rem;
    font-size: var(--font-size-text);
}

td{
    padding: 1rem;
    font-size: var(--font-size-small-text);
}

.dashboard-table tr:nth-child(even){
    background-color: #E4E4E4;
}

.category-image{
    width: 75px;
    height: 75px;
}

.add-category-btn{
    margin-bottom: 2.5rem;
    cursor: pointer;
    border: none;
    padding: 10px;
    border-radius: 25px;
    background-color: #93fc5f;
    width: 250px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    transition: .5s ease;
    text-decoration: none;
    font-family: var(--font-family-main);
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
}

.add-category-btn svg{
    width: 20px;
    height: 20px;
}

.edit-category-btn{
    cursor: pointer;
    border: none;
    padding: 10px;
    border-radius: 25px;
    background-color: #FCC05F;
    width: 90px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    transition: .5s ease;
    text-decoration: none;
    font-family: var(--font-family-main);
    color: black;
}

.delete-category-btn{
    cursor: pointer;
    border: none;
    padding: 10px;
    border-radius: 25px;
    background-color: #fc5f5f;
    width: 90px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    transition: .5s ease;
    text-decoration: none;
    font-family: var(--font-family-main);
    color: black;
}

.edit-category-btn:hover, .delete-category-btn:hover{
    transform: scale(102%);
}