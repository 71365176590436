@import '../../../components/variables.css';

.dashboard-login-section {
    position: relative;
    height: 100vh;
    width: 100vw;
    background-image: url('/public/uploads/franchise/Franchise5.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard-login-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.dashboard-login-content{
    z-index: 1000;
    background-color: white;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    width: 33%;
    height: 400px;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    font-family: var(--font-family-main);
}

.dashboard-login-content-header{
    font-size: var(--font-size-sub-header);
    color: var(--color-chocolate);
}

.dashboard-login-form{
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
}

.login-action-btn{
    cursor: pointer;
    border: none;
    padding: 15px;
    border-radius: 25px;
    background-color: #FCC05F;
    width: 200px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    transition: .5s ease;
    text-decoration: none;
    font-family: var(--font-family-main);
    color: black;
}