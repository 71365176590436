@import '../../../components/variables.css';

#dashboard-section {
  display: flex;
}

#dashboard-aside {
  top: 0;
  position: sticky;
  width: 350px;
  height: 100vh;
  background-color: #333;
  color: #fff;
  padding: 20px;
  font-family: var(--font-family-main);
  z-index: 1000;
}

.logo {
  max-width: 100px;
  margin-bottom: 10px;
}

.dashboard-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.dashboard-header h2 {
  font-size: 1.5rem;
  margin-left: 10px;
}

.dashboard-nav{
  background-color: transparent;
}

.dashboard-nav ul {
  list-style: none;
  padding: 0;
}

.dashboard-nav li {
  margin-bottom: 10px;
}

.dashboard-nav a {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  transition: color 0.3s;
}

.dashboard-nav a:hover {
  color: #ff9900;
}
  