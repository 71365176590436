@import '../../components/variables.css';

.invalid-route-section{
    position: relative;
    height: 100vh;
    width: 100vw;
    background-image: url('/public/uploads/franchise/Franchise5.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    display: flex;
    align-items: center;
    justify-content: center;
}

.invalid-route-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.invalid-route-content{
    z-index: 1000;
    color: white;
}

.invalid-route-content{
    font-family: var(--font-family-main);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    width: 75%;
}

.invalid-route-content h1{
    font-size: 70pt
}

.invalid-route-content h5{
    font-size: var(--font-size-sub-header);
}

.back-btn{
    cursor: pointer;
    border: none;
    padding: 15px;
    border-radius: 25px;
    background-color: #FCC05F;
    width: 200px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    transition: .5s ease;
    text-decoration: none;
    font-family: var(--font-family-main);
    color: var(--color-chocolate);
}

.back-btn:hover{
    transform: scale(105%);
}