@import '../variables.css';

nav {
    background-color: var(--color-chocolate);
}

.navbarChocolate {
  left: 0;
  top: 145px;
  position: absolute;
  width: 100%;
  z-index: 10000;
}

.navbarContainer {
  position: relative;
  width: 100%;
  height: 150px;
  padding: 0% 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbarBrandLogo {
  padding-top: 1.5rem;
  width: 130px;
}

.navbarLinksList {
  list-style: none;
  text-decoration: none;
  display: flex;
  gap: 3rem;
}

.navbarLink {
  color: #FFFF;
  font-size: 24px;
  font-family: var(--font-family-main);
}

.navbarLink a{
  color: #FFFF;
  text-decoration: none;
  cursor: pointer;
}

.hamburgerMenu{
  color: white;
  width: 50px;
  height: 50px;
  display: none;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .navbarContainer {
    padding: 5% 3%;
  }

  .navbarLinksList{
    gap: 1.5rem;
  }

  .navbarLink{
    font-size: var(--font-size-text);
  }
}

@media screen and (max-width: 767px) {

  .navbarBrandLogo{
    width: 100px;
  }

  .navbarContainer {
    z-index: 10000;
    background-color: var(--color-chocolate);
    position: relative;
    padding: 2% 2%;
    transition: 1s ease;
  }

  .navbarLinksList {
    height: calc(100vh - 150px);
    position: fixed;
    top: 150px;
    right: -100%;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, .95);
    width: 100vw;
    padding: 15% 10%;
    transition: 0.5s ease;
    overflow-y: auto;
  }
  
  .navbarContainerFixed {
    top: 0;
    position: fixed;
    background-color: var(--color-chocolate);
    transition: .5s ease;
  }

  .active {
    right: 0;
  }

  .hamburgerMenu {
    display: block;
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
  }

}