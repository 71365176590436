@import '../../components/variables.css';

.franchise-section{
    padding: 15% 10% 5% 10%;
    font-family: var(--font-family-main);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.franchise-header{
    font-size: var(--font-size-header);
    color: var(--color-chocolate);
}

.franchise-content-container{
    display: flex;
    gap: 1.5rem;
}

.franchise-text-container{
    width: 50%;
    font-size: var(--font-size-text);
}

.franchise-content-text{
    margin-bottom: 1rem;
}

.franchise-image-container{
    width: 50%;
    display: flex;
    justify-content: center;
}

.franchise-image-container svg{
    color: var(--color-chocolate);
    width: 280px;
    height: 280px;
}

@media only screen and (max-width: 991px){

    .franchise-content-container{
        flex-direction: column;
    }

    .franchise-text-container{
        width: 100%;
    }

    .franchise-image-container{
        width: 100%;
    }
}

@media only screen and (max-width: 575px) {

    .franchise-header{
        font-size: var(--font-size-sub-header);
    }

    .franchise-content-text{
        font-size: var(--font-size-small-text);
    }

    .franchise-image-container svg{
        width: 150px;
        height: 150px;
    }
}