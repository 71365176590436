@import '../variables.css';

/* CSS for the Hero Section */

#heroSection{
    background-color: red;
    width: 100%;
    background: rgb(218,156,95);
    background: linear-gradient(90deg, rgba(218,156,95,1) 4%, rgba(236,212,159,1) 94%);
    padding: 100px 10%;
    display: flex;
    align-items: center;
}

.waveImage{
    width: 100%;
    height: 300px;
}
  