.menu-page-section {
  padding: 15% 10%;
}

.menu-selector-header {
  font-family: var(--font-family-main);
  font-size: var(--font-size-header);
  color: var(--color-chocolate);
  margin-bottom: 7%;
}

.selector-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.selector-item {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(15% - 1rem);
  gap: 0.5rem;
  padding: 10px;
  transition: .5s ease;
}

.selector-item:hover{
transform: scale(115%);
}

.selector-item.active .selector-item-image{
border: 2px dashed var(--color-chocolate);
border-radius: 100px;
}

.selector-item-image {
  width: 100px;
  height: 100px;
  padding: 5px;
}

.selector-item-name {
  font-family: var(--font-family-main);
  font-size: var(--font-size-small-text);
}

.menu-items {
padding-top: 10%;
}

.menu-items-list {
display: flex;
flex-wrap: wrap;
gap: 3rem;
}

.menu-item {
cursor: pointer;
display: flex;
flex-direction: column;
align-items: center;
gap: 1rem;
width: calc(25% - 2.5rem);
transition: .5s ease;
}

.menu-item:hover{
transform: scale(105%);
}

.menu-item-image {
width: 150px;
height: 150px;
}

.menu-item-name {
height: 30px;
font-family: var(--font-family-main);
font-size: var(--font-size-text);
color: var(--color-chocolate);
}

.menu-item-price {
font-family: var(--font-family-main);
font-size: var(--font-size-text);
}

.no-products-message{
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
gap: 1.5rem;
font-family: var(--font-family-main);
font-size: var(--font-size-sub-header);
}

.no-products-message svg{
width: 100px;
height: 100px;
}

@media (max-width: 1200px) {
.menu-items-list {
    gap: 3rem;
}
.menu-item {
    width: calc(33.33% - 2rem);
}
}

@media (max-width: 800px) {

.selector-item {
  width: calc(20% - 1rem);
}

.menu-items-list {
    gap: 2rem;
}
.menu-item {
    width: calc(50% - 1rem);
}

.no-products-message{
  font-size: var(--font-size-text);
  gap: .5rem;
}

.no-products-message svg{
  width: 75px;
}
}

@media (max-width: 600px) {

.selector-item {
  width: 44%
}

.menu-items-list {
    gap: 1rem;
}
.menu-item {
    width: 100%;
}

.no-products-message{
  font-size: var(--font-size-text);
  gap: .3rem;
}

.no-products-message svg{
  width: 50px;
}
}

