@import '../../components/variables.css';

.cookie-consent-container {
    background-color: var(--color-chocolate);
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 1rem;
    text-align: center;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.25);
    z-index: 1000;
    font-family: var(--font-family-main);
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.cookie-consent-container p {
    margin: 0;
    font-size: var(--font-size-text);
}
